module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zennary Presantations","short_name":"Zennary","start_url":"/","background_color":"#2fb9ea","theme_color":"#2fb9ea","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-49293673-4","head":true,"anonymize":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gaston Hummel","short_name":"Gaston Hummel","start_url":"/","background_color":"#68a9ed","theme_color":"#68a9ed","display":"standalone","icon":"src/images/icon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
